import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w01EAT03Familie = () => (
  <Layout>
    <SEO title="w01EAT03Familie" />

    <h1 id="ueberschrift">Eigenorganisation</h1>
    <h2>im Team Familie: <b><u>Wer</u></b> macht <b><u>was</u></b> <b><u>wie</u></b> und vor allem <b><u>wann</u></b>? &emsp;  ©&nbsp;1989</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w01EAT03Familie&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Spätestens wenn die Frage aufkommt, wie "xy" vergessen werden konnte oder warum "z" vergeblich gesucht wird,
        ploppt zumindest für einen Bruchteil einer Sekunde der Wunsch nach einer besseren Organisation auf.
        <br />
        Doch wie lässt sich das bewerkstelligen? Man tut doch schon so viel!
      </p>
      <p>  
        *Eigenorganisation* ist mehr als nur die Autoschlüssel ans Schlüsselbrett zu hängen oder einen Termin
        sogleich in den Terminkalender einzutragen. Und *Eigenorganisation* ist auch weniger als eben dieses.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, sich und Ihre Familie besser zu organisieren.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w01EAT03Familie
